var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "left": "",
                  "small": ""
                },
                on: {
                  "click": _vm.goBackToOrderList
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("Tilbake")])]), _vm._v(" Økonomi - " + _vm._s(_vm.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.singleOrder ? _c('SingleOrderHeaderAndSubmitActions', {
          attrs: {
            "singleOrder": _vm.singleOrder,
            "isEditing": _vm.isEditing
          },
          on: {
            "setEdit": function setEdit($event) {
              _vm.isEditing = true;
            },
            "cancelOrder": _vm.cancelOrder,
            "handleOrder": _vm.handleOrderAction
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.singleOrder ? [_c('v-row', {
    staticClass: "ma-1"
  }, [_vm.singleOrder && _vm.customerPersons && _vm.customerOrganizations ? _c('SingleOrderDetailValues', {
    attrs: {
      "isEdit": _vm.isEditing,
      "order": _vm.singleOrder,
      "customerPersons": _vm.customerPersons,
      "customerOrganizations": _vm.customerOrganizations
    },
    on: {
      "cancelEdit": function cancelEdit($event) {
        _vm.isEditing = false;
      },
      "updateOrderValues": _vm.updateOrderValues
    }
  }) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.singleOrder && _vm.customerPersons && _vm.customerOrganizations ? _c('CourseSingleOrderTable', {
    attrs: {
      "isEditable": _vm.isEditable,
      "articleNos": _vm.articleNos,
      "orderLinesWithUUID": _vm.orderLinesWithUUID,
      "orderValuesWithUUID": _vm.orderValuesWithUUID
    },
    on: {
      "updateOrderLines": _vm.updateOrderLines
    }
  }) : _vm._e()], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
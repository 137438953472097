
import { api } from "@/api/api";
import { ApiGetCustomerDto, ApiGetOrderDto, ApiOrderLineDto } from "@/api/generated/Api";
import CourseSingleOrderTable from "@/components/course/economy/CourseSingleOrderTable.vue";
import SingleOrderDetailValues from "@/components/course/economy/SingleOrderDetailValues.vue";
import SingleOrderHeaderAndSubmitActions from "@/components/course/economy/SingleOrderHeaderAndSubmitActions.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { CourseOrderPaymentType } from "@/shared/enums/courseOrderPaymentType.enum";
import { CourseOrderStatus } from "@/shared/enums/courseOrderStatusEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { labelMap, mapOrderToApiUpdateDraftDto, queueOrder } from "@/shared/helpers/courseOrderHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { OrderLineWithUUID } from "@/shared/interfaces/OrderWithUIID.interface";
import { useRouteComputed, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

interface InputFieldDetails {
  value?: string | number | boolean | null;
  label: string;
  rules?: any;
  inputFieldType: string;
}

export default defineComponent({
  name: "CourseEconomyDetailsPage",
  components: {
    CourseSingleOrderTable,
    SingleOrderHeaderAndSubmitActions,
    SingleOrderDetailValues,
    BaseLayout,
  },
  setup() {
    const router = useRouter();
    const route = useRouteComputed();
    const store = useStore<StoreState>();
    const singleOrder = ref<ApiGetOrderDto>();
    const inputFieldDetails = ref<InputFieldDetails[]>([]);
    const invoiceRecipientDetails = ref<InputFieldDetails[]>([]);
    const customerOrganizations = ref<ApiGetCustomerDto[]>();
    const customerPersons = ref<ApiGetCustomerDto[]>();
    const isEditing = ref(false);
    const courseName = computed(() => store.state.courses.course.courseName);
    const articleNos = computed(() => store.state.courses.course.economy?.articleNos);

    const generateUUIDForOrderlines = (orderLines: ApiOrderLineDto[]) =>
      deepCloneObject(orderLines.map((orderLine) => ({ ...orderLine, temporaryId: generateUuid() })));

    const orderLinesWithUUID = computed(() => {
      if (!singleOrder.value?.orderLines) {
        return;
      }
      return generateUUIDForOrderlines(singleOrder.value.orderLines);
    });
    const orderValuesWithUUID = computed(() => {
      if (!singleOrder.value) {
        return;
      }
      return deepCloneObject({ ...singleOrder.value, orderLines: orderLinesWithUUID.value });
    });

    const isEditable = computed(() => {
      if (!orderValuesWithUUID.value) {
        return;
      }
      return (
        (orderValuesWithUUID.value.status?.id === CourseOrderStatus.DraftValue &&
          !orderValuesWithUUID.value.contraEntryOrderId) ||
        (orderValuesWithUUID.value.contraEntryOrderId &&
          orderValuesWithUUID.value.paymentType !== CourseOrderPaymentType.severalDeductions)
      );
    });

    const getChipColorByStatusId = (statusCode: CourseOrderStatus) => chipColorByStatus[statusCode] || "";

    const loadOrder = async () => {
      singleOrder.value = (await api.order.getOrderAsync(+route.value.params.orderNo)).data;
    };

    const handleOrderAction = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!singleOrder.value || !singleOrder.value.status) {
          return;
        }
        if (singleOrder.value.status.id === CourseOrderStatus.DraftValue) {
          await queueOrder(store, +route.value.params.orderNo);
        } else if (singleOrder.value.status.id === CourseOrderStatus.SendtValue) {
          const orderId = (await api.order.createContraEntry(+route.value.params.orderNo)).data.id;
          if (!orderId) {
            return;
          }
          router.push({
            name: SingleCourseRouteNames.CourseEconomyDetails,
            params: {
              id: route.value.params.id,
              orderNo: orderId.toString(),
            },
          });
        }
        await loadOrder();
      });
    };

    const cancelOrder = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!singleOrder.value || !singleOrder.value.status) {
          return;
        }
        const response = await api.order.cancel(+route.value.params.orderNo);
        if (response.status === 204) {
          singleOrder.value.status.id = CourseOrderStatus.CanceledValue;
          openNotification(store, NotificationItemType.Success, "Ordre avbrutt");
          await loadOrder();
        }
      });
    };

    const updateOrderLines = (orderLines: OrderLineWithUUID[]) => {
      if (!singleOrder.value) {
        return;
      }
      singleOrder.value = { ...singleOrder.value, orderLines };
    };

    const updateOrderValues = async (updatedOrderValues: ApiGetOrderDto) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const orderValuesWithUserId = mapOrderToApiUpdateDraftDto(updatedOrderValues);
        orderValuesWithUserId.orderLines?.map((orderline) =>
          orderline.user?.id === undefined ? delete orderline.user : orderline
        );
        if (!orderValuesWithUserId) {
          return;
        }
        const updateOrderResponse = await api.order.updateDraft(+route.value.params.orderNo, orderValuesWithUserId);
        if (updateOrderResponse.status === 204) {
          openNotification(store, NotificationItemType.Success, "Ordreutkast oppdatert");
          singleOrder.value = updatedOrderValues;
          isEditing.value = false;
        }
      });
    };

    const goBackToOrderList = () =>
      router.push({
        name: SingleCourseRouteNames.CourseEconomy,
        params: {
          id: route.value.params.id,
        },
      });

    onParamChange(loadOrder);

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        [customerOrganizations.value, customerPersons.value] = await Promise.all([
          (await api.customer.getCustomerOrganizationsAsync()).data,
          (await api.customer.getCustomerPersonsAsync()).data,
        ]);
      });
    });

    return {
      labelMap,
      isEditing,
      articleNos,
      isEditable,
      courseName,
      singleOrder,
      customerPersons,
      inputFieldDetails,
      orderLinesWithUUID,
      orderValuesWithUUID,
      customerOrganizations,
      invoiceRecipientDetails,
      cancelOrder,
      updateOrderLines,
      goBackToOrderList,
      updateOrderValues,
      handleOrderAction,
      getChipColorByStatusId,
    };
  },
});

const chipColorByStatus = {
  [CourseOrderStatus.ValidatedValue]: "cyan",
  [CourseOrderStatus.QueueingValue]: "green",
  [CourseOrderStatus.QueuedValue]: "green",
  [CourseOrderStatus.SendingValue]: "primary",
  [CourseOrderStatus.SendtValue]: "primary",
  [CourseOrderStatus.FailedSendingValue]: "red",
  [CourseOrderStatus.FailedOrderValue]: "red",
  [CourseOrderStatus.CanceledValue]: "red",
  [CourseOrderStatus.FailedQueueingValue]: "red",
  [CourseOrderStatus.DraftValue]: "",
} as const;
